import commonHelper from '@/app/utility/common.helper.utility';
export default {
  name: 'AddEditHeaderSummary',
  props: ['dunningSummaryRowDetails'],
  watch: {
    currentPage: function() {
      this.getDunningHeaderDetailById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getDunningHeaderDetailById();
    }
  },
  data() {
    return {
      showAddCustomerRuleMappingModal: false,
      customerName: null,
      erpCustomerNumber: null,
      editMode: false,
      customerShortName: null,
      selectAllCheckBox: false,
      active: false,
      ruleName: {
        value: null,
        text: null
      },
      parent_value_set_id: null,
      showValueSetModal: false,
      emailSendStatus: {
        value: null,
        text: null
      },
      creationFromDate: null,
      creationToDate: null,
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),

      payload: null,
      unsubscribe: null,
      currentPage: 1,
      totalRows: null,
      loader: false,
      vsetCode: null,
      setTimeVsetCode: null,
      legalEntity: {
        value: null,
        text: null
      },
      operatingUnit: {
        value: null,
        text: null
      },
      periodType: null,
      OperatingUnitList: [
        {
          value: '2A',
          text: '2A'
        },
        {
          value: '2B',
          text: '2B'
        }
      ],
      addEditHeaderSummaryData: [],
      addEditHeaderSummaryFields: [
        {
          key: 'trx_number'
        },
        {
          key: 'trx_date'
        },
        {
          key: 'trx_amount',
          label: 'Invoice Amount'
        },
        {
          key: 'trx_date_of_submision'
        },
        {
          key: 'trx_outstanding_amount'
        },
        {
          key: 'ageing_days',
          label: 'Ageing Days Post CP'
        },
        {
          key: 'status'
        },
        {
          key: 'reference_id'
        },
        {
          key: 'dunning_detail_id'
        }
      ]
    };
  },

  mounted() {
    if (this.dunningSummaryRowDetails) {
      this.getDunningHeaderDetailById();
    } else {
      this.editMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showAddCustomerRuleMappingModal = false;
        }
        if (
          actionName === 'download' 
        ) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.totalRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'dunningAdmin/getDunningHeaderDetailById',
            'dunningDetails',
            () => (this.loader = false)
          );
        }
      }
    });
  },
  methods: {
    getDunningHeaderDetailById() {
      this.loader = true;
      this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        dunning_header_id: this.dunningSummaryRowDetails.dunning_header_id
      };
      this.$store
        .dispatch('dunningAdmin/getDunningHeaderDetailById', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.addEditHeaderSummaryData = resp.data.data.page;
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
